import { lazy, Suspense } from 'react';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import MainLayout from 'components/MainLayout';
import CallBack from 'pages/authentication/login/Callback';
import SilentRenew from 'pages/authentication/login/SilentRenew';
import { RouteObject } from 'react-router';


const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('pages/authentication/Login')));

const LandingPage = Loadable(lazy(() => import('pages/LandingPage')));
const Home = Loadable(lazy(() => import('pages/home/Home')));
const Dashboard = Loadable(lazy(() => import('pages/analytics/dashboard/Dashboard')));
const TransactionReports = Loadable(lazy(() => import('pages/analytics/transaction-reports/TransactionReports')));
const ReconciliationReports = Loadable(lazy(() => import('pages/analytics/reconciliation-reports/DailyReconciliationReports')));
const Profile = Loadable(lazy(() => import('pages/account/Profile')));

const PaymentView = Loadable(lazy(() => import('pages/slug-payment/PaymentView')));
const TransactionsList = Loadable(lazy(() => import('pages/payments/transactions/Transactions')));
const TransactionsStatement  = Loadable(lazy(() => import('pages/payments/transactions/TransactionsStatement')));
const CollectionsList = Loadable(lazy(() => import('pages/payments/collections/Collections')));
const DisbursementsList = Loadable(lazy(() => import('pages/payments/disbursements/single/Disbursements')));
const DisbursementDetails = Loadable(lazy(() => import('pages/payments/disbursements/single/DisbursementDetails')));

const BulkDisbursements = Loadable(lazy(() => import('pages/payments/disbursements/bulk/BulkDisbursements')));
const BulkDisbursementCreator = Loadable(lazy(() => import('pages/payments/disbursements/bulk/BulkDisbursementCreator')));
const BulkDisbursementDetails = Loadable(lazy(() => import('pages/payments/disbursements/bulk/details/BulkDisbursementDetails')));

const TopupsList = Loadable(lazy(() => import('pages/payments/topups/TopUps')));
const LiquidationsList = Loadable(lazy(() => import('pages/payments/liquidations/Liquidations')));

const BeneficiariesList = Loadable(lazy(() => import('pages/payments/beneficiaries/Beneficiaries')));
const BeneficiaryDetails = Loadable(lazy(() => import('pages/payments/beneficiaries/BeneficiaryDetails')));

const StaffList = Loadable(lazy(() => import('pages/management/staff/Staff')));

const WalletsList = Loadable(lazy(() => import('pages/management/wallets/list/Wallets')));
const WalletDetails = Loadable(lazy(() => import('pages/management/wallets/details/WalletDetails')));

const PartnersList = Loadable(lazy(() => import('pages/management/partners/Partners')));
const PartnerDetails = Loadable(lazy(() => import('pages/management/partners/PartnerDetails')));

const VendorBalances = Loadable(lazy(() => import('pages/management/settings/vendor-balances/VendorBalances')));
const Banks = Loadable(lazy(() => import('pages/management/settings/banks/Banks')));
const StanbicMiniStatementView = Loadable(lazy(() => import('pages/management/settings/escrow-bank-statements/stanbic/StanbicMiniStatementView')));
const Hangfire = Loadable(lazy(() => import('pages/management/Hangfire')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));
const ServerError = Loadable(lazy(() => import('pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'login-callback',
        element: <CallBack />
      },
      {
        path: 'silent-renew',
        element: <SilentRenew />
      }
    ]
  },
  {
    path: 'home',
    element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Home />
      }
    ]
  },
  {
    path: 'analytics',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'transaction-reports',
        element: <TransactionReports />
      },
      {
        path: 'reconciliation-reports',
        element: <ReconciliationReports />
      }
    ]
  },
  {
    path: 'payments',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <TransactionsList />
      },
      {
        path: 'transactions',
        element: <TransactionsList />
      },
      {
        path: 'transactions-statement',
        element: <TransactionsStatement />
      },
      {
        path: 'collections',
        element: <CollectionsList />
      },
      {
        path: 'disbursements',
        children: [
          {
            path: ':requestId',
            element: <DisbursementDetails />
          },
          {
            path: '',
            element: <DisbursementsList />
          },
        ]
      },
      {
        path: 'bulk-disbursements',
        children: [
          {
            path: '',
            element: <BulkDisbursements />
          },
          {
            path: 'create',
            element: <BulkDisbursementCreator />
          },
          {
            path: ':bulkId',
            element: <BulkDisbursementDetails />
          }
        ]
      },
      {
        path: 'topups',
        element: <TopupsList />
      },
      {
        path: 'liquidations',
        element: <LiquidationsList />
      },
      {
        path: 'beneficiaries',
        element: <BeneficiariesList />
      },
      {
        path: 'beneficiaries/:beneficiaryId',
        element: <BeneficiaryDetails />
      },
    ]
  },
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <WalletsList />
      },
      {
        path: 'wallets',
        element: <WalletsList />
      },
      {
        path: 'wallets/:walletId',
        element: <WalletDetails />
      },
      {
        path: 'staff',
        element: <StaffList />
      },
      {
        path: 'partners',
        element: <PartnersList />
      },
      {
        path: 'partners/:partnerId',
        element: <PartnerDetails />
      },
      {
        path: 'vendor-balances',
        element: <VendorBalances />
      },
      {
        path: 'stanbic-mini-statement',
        element: <StanbicMiniStatementView />
      },
      {
        path: 'banks',
        element: <Banks />
      },
      {
        path: 'hangfire',
        element: <Hangfire />
      }
    ]
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  },
  {
    path: '*',
    element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <LandingPage />
      }
    ]
  },
  {
    path: 'p/:slug',
    element: <PaymentView />
  },
  {
    path: 'error',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
