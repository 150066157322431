export const contactCategories = ['Person', 'Company'];
export const idCategories = ['NIN', 'FCN', 'NSSF', 'Passport', 'DrivingPermit', 'VillageCard', 'Other'];
export const genderCategories = ['Male', 'Female'];
export const salutationCategories = ['Mr', 'Mrs', 'Ms', 'Dr', 'Prof'];
export const companyCategories = ['Limited', 'Ngo', 'Other'];
export const civilStatusCategories = ['Single', 'Married', 'Divorced', 'Other'];
export const emailCategories = ['Personal', 'Work', 'Other'];
export const phoneCategories = ['Mobile', 'Office', 'Home', 'Fax', 'Other'];
export const addressCategories = ['Home', 'Work', 'Other'];
export const hobbyCategories = ['FootBall', 'Work', 'Other'];
export const currencyCategories = ['UGX', 'ITX'];
